<template>
    <v-container fluid>


        <v-row>
            <v-col class="text-center mt-15" sm="12" cols="12">
                {{$t('please_choose_type_of_transport_when_leaving_terminal')}}
            </v-col>
            <v-col class="text-center" sm="12" cols="12">
                <v-card slot="footer" key="footer" class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"

                        v-for="(departure, i) in departures"
                        :key="i"
                >
                    <v-container class="fill-height py-10 justify-center"  @click="getEntrance(departure)">
                        <v-icon > {{ departure.slug === 'entrance_auto' ? 'mdi-truck-minus-outline' :'mdi-train'}}  mdi-48px</v-icon>
                            <v-card-subtitle> {{ departure.slug === 'entrance_auto' ? $t('list_of_current_car_allow_departure_inspections') : $t('list_of_current_inspections_allow_departure_by_railway')}}</v-card-subtitle>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <v-overlay :value="loading" z-index="10" color="primary">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </v-container>
</template>
<script>

import {mapGetters} from "vuex"


export default {
    name: "Departures",


    data() {
        return {

            options: {},
            loading: false,
            departures: [],

        };
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems', 'phoneMask']),
    },
    mounted() {
        this.options.itemsPerPage = this.perPage = this.itemsPerPage
        this.getEntrances();
    },

    methods: {
        getEntrance(item) {
            const regex = /entrance/i;
            let slug = item.slug.replace(regex, 'departure')
            this.$router.push({
                name: slug,
                params: {
                     id: item.uuid
                }
            })
        },
        async getEntrances() {
            this.loading = true;
            let params = {};

            await this.$http
                .get("partner/container_type_receipt", {
                    params: params,
                })
                .then(res => {
                    this.departures = res.body.data
                })
                .catch(err => {
                    this.departures = []
                    this.$toastr.error(this.$t('failed_to_get_list_departures'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
    }
}
</script>
